import React from "react"
import {graphql} from "gatsby"
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    Container,
    Row,
} from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleBlock from "../components/blocks/TitleBlock"
import Img from "gatsby-image"
import HorizontalCardBlock from "../components/blocks/HorizontalCardBlog"
import Photos from "../components/general/Photos"
import ContactInfo from "../components/general/ContactInfo"
import Map from "../components/general/Map"
import BigHeader from "../components/header"

const IndexPage = ({data}) => {
    React.useEffect(() => {
        document.body.classList.add("sections-page")
        document.body.classList.add("sidebar-collapse")
        document.documentElement.classList.remove("nav-open")
        var href = window.location.href.substring(
            window.location.href.lastIndexOf("#/") + 2
        )
        var hrefId = href.substring(href.lastIndexOf("#") + 1)

        if (href.lastIndexOf("#") > 0) {
            document.getElementById(hrefId).scrollIntoView({
                behavior: "smooth",
                inline: "nearest",
            })
        }

        return function cleanup() {
            document.body.classList.remove("sections-page")
            document.body.classList.remove("sidebar-collapse")
        }
    })

    return (
        <Layout>
            <SEO title="Strona główna"/>
            <BigHeader
                title={`Komfortowe apartamenty w&nbsp;sąsiedztwie plaży`}
                description={`Morska&nbsp;5 to&nbsp;nowy obiekt wypoczynkowy usytuowany
        w&nbsp;pięknym otoczeniu w pierwszej linii nadmorskiej
        zabudowy, zaledwie 50 metrów od plaży. Wygodne i&nbsp;dobrze
        wyposażone pokoje pozwalają cieszyć się komfortem
        na&nbsp;wakacjach.`}
                ctaText={`Rezerwuj pobyt`}
                ctaTo={`/#contact`}
            />

            <Container>
                <div className="section pb-0">
                    <Row>
                        <Col className="ml-auto mr-auto text-center title" md="6">
                            <TitleBlock
                                title="Nowe miejsce na mapie Niechorza"
                                description="Komfortowe wnętrza, nowoczesny budynek, doskonała lokalizacja. Mamy wszystko, co niezbędne, by w pełni korzystać z wszelkich uroków bałtyckiego kurortu."
                                headingLevel="h2"
                                extendedClasses="mb-0 color-sea font-weight-bold"
                            />
                        </Col>
                    </Row>
                    <div className="section-story-overview pb-0">
                        <Row>
                            <Col className="ml-auto mr-auto" md="4">
                                <div className="image-container image-left">
                                    <Img
                                        fluid={data.story1.childImageSharp.fluid}
                                        style={{position: "asbolute"}}
                                    />
                                </div>
                                <div className="text-under-image">
                                    <h3
                                        style={{
                                            color: "#3D3D3F",
                                            paddingTop: "30px",
                                        }}
                                        className="text-center text-md-left"
                                    >
                                        Pokoje i&nbsp;apartamenty na wynajem - Morska&nbsp;5
                                    </h3>
                                </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <div className="image-container image-right">
                                    <Img
                                        fluid={data.story2.childImageSharp.fluid}
                                        style={{position: "asbolute"}}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="10">
                                <p
                                    style={{
                                        textAlign: "justify",
                                    }}
                                >
                                    Morska&nbsp;5 to&nbsp;nowoczesny obiekt wypoczynkowy ulokowany
                                    w&nbsp;bezpośrednim sąsiedztwie zarówno czystej, szerokiej
                                    i&nbsp;piaszczystej plaży, jak&nbsp;również sklepów,
                                    restauracji i&nbsp;barów.
                                </p>
                                <p
                                    style={{
                                        textAlign: "justify",
                                    }}
                                >
                                    Okoliczne zabudowania i skwery utrzymane są w&nbsp;tradycyjnym
                                    nadmorskim klimacie i&nbsp;otoczone naturalnym pięknem drzew i
                                    wydm. Budynek wyposażony jest w windę i&nbsp;stanowiska
                                    garażowe, a każdy apartament posiada swój balkon.
                                </p>
                                <p
                                    style={{
                                        textAlign: "justify",
                                    }}
                                >
                                    Na wyższych kondygnacjach z balkonów roztacza się wspaniały
                                    widok na Bałtyk i latarnię morską. Zadbany teren wokół obiektu
                                    zapewnia miejsca do wypoczynku dla naszych gości, stanowisko
                                    do grillowania z ławami i wygodnymi siedziskami.
                                </p>
                                <p
                                    style={{
                                        textAlign: "justify",
                                    }}
                                >
                                    Zadbaliśmy także o najmłodszych, którzy mogą spędzać czas na
                                    bezpiecznym placu zabaw lub w sali zabaw, gdzie dostępne są
                                    klocki oraz zabawki, a także konsola do gier.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>

            <Container>
                <div className="section pt-0 pb-0">
                    <div className="section-story-overview pb-0">
                        <Row>
                            <Col className="ml-auto" md="6">
                                <div className="image-container image-left">
                                    <Img
                                        fluid={data.story4.childImageSharp.fluid}
                                        style={{position: "asbolute"}}
                                    />
                                    <p
                                        className="blockquote border-sea color-sea"
                                        style={{fontWeight: 500}}
                                    >
                                        "Niechorze co roku przyciąga zarówno miłośników błogiego
                                        lenistwa i kąpieli słonecznych, jak również amatorów sportów
                                        wodnych i wycieczek pieszych oraz rowerowych. " <br></br>
                                        <br/>
                                    </p>
                                </div>
                                <div className="image-container">
                                    <Img
                                        fluid={data.story3.childImageSharp.fluid}
                                        style={{position: "asbolute"}}
                                    />
                                </div>
                            </Col>
                            <Col className="mr-auto" md="4">
                                <div className="image-container image-right">
                                    <Img
                                        fluid={data.story5.childImageSharp.fluid}
                                        style={{position: "asbolute"}}
                                    />
                                </div>
                                <h3 className="text-center text-md-left">
                                    Niechorze - zaskakuje różnorodnością
                                </h3>
                                <p className="text-justify text-md-left">
                                    Niechorze to dawna wieś rybacka, która obecnie jest jedną z
                                    najszybciej rozwijających się miejscowości turystycznych nad
                                    Bałtykiem. Licząca około 1000 mieszkańców wieś w Gminie Rewal
                                    słynie z przepięknego położenia i latarni morskiej. Zachodnia
                                    część Niechorza wznosi się na 22-metrowym klifie z cudownymi
                                    widokami. Część wschodnia wsi to piaszczysta mierzeja, która
                                    oddziela morze od przybrzeżnego jeziora Liwia Łuża.
                                </p>
                                <p className="text-justify text-md-left">
                                    Zaledwie 50 metrów dzieli Morską 5 od zejścia na szeroką,
                                    czystą plażę, bardzo blisko jest także do głównej ulicy, gdzie
                                    skorzystać można z oferty wielu restauracji, kawiarni oraz
                                    sklepów. Niecały kilometr od naszego obiektu znajduje się
                                    symbol Niechorza - latarnia morska. Warto także wybrać się na
                                    dworzec zabytkowej kolei wąskotorowej (250 m) lub skorzystać z
                                    wielu urokliwych tras spacerowych.
                                </p>
                                <p className="text-justify text-md-left">
                                    Miłośników wycieczek rowerowych z pewnością zachwyci szlak
                                    rowerowy biegnący wśród lasów, wzdłuż linii brzegowej z
                                    Niechorza aż do Mrzeżyna lub malownicza trasa rowerowa wokół
                                    jeziora Liwia Łuża i okoliczny rezerwat ornitologiczny.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>

            <div className="section front__blog pt-0 pb-0" id="apartments">
                <div className="section-story-overview cd-section pt-0 pb-0">
                    <div className="blogs-3">
                        <Container className="mb-4">
                            <Row>
                                <Col className="ml-auto mr-auto text-center title mb-4" md="6">
                                    <TitleBlock
                                        title="Doskonale wyposażone pokoje i apartamenty czekają na Ciebie"
                                        description="Wszystkie nasze apartamenty posiadają aneks kuchenny, łazienkę, balkon i pełne wyposażenie."
                                        headingLevel="h2"
                                        extendedClasses="mb-0 color-gold font-weight-bold"
                                    />
                                </Col>
                            </Row>
                        </Container>

                        <Container className="apartments">
                            <Row>
                                <Col className="ml-auto mr-auto" md="10">
                                    <HorizontalCardBlock
                                        title="Apartament 2-osobowy"
                                        description="Przytulny, w pełni wyposażony apartament dla dwóch osób z aneksem kuchennym i łazienką oraz balkonem. Zobacz zdjęcia i szczegóły wyposażenia."
                                        link="/apartament-2-osobowy"
                                        image={data.roomCovers.edges[0].node.childImageSharp.fluid}
                                    />

                                    <HorizontalCardBlock
                                        title="Apartament 3-osobowy"
                                        description="Składający się z jednego pokoju, komfortowo wyposażony apartament dla trzech osób z aneksem kuchennym i łazienką oraz balkonem. Zobacz zdjęcia i szczegóły wyposażenia."
                                        link="/apartament-3-osobowy"
                                        image={data.roomCovers.edges[1].node.childImageSharp.fluid}
                                    />

                                    <HorizontalCardBlock
                                        title="Apartament 3-osobowy (dwupokojowy)"
                                        description="Trzyosobowy, w pełni wyposażony apartament składający się z pokoju dziennego z rozkładaną sofą, aneksu kuchennego, osobnej sypialni z szerokim łóżkiem oraz łazienki i balkonu. Zobacz zdjęcia pokoju i szczegóły wyposażenia."
                                        link="/apartament-3-osobowy-2-pokojowy"
                                        image={data.roomCovers.edges[2].node.childImageSharp.fluid}
                                    />

                                    <HorizontalCardBlock
                                        title="Apartament 4-osobowy (dwupokojowy)"
                                        description="Komfortowy, dwupokojowy apartament dla czterech osób składa się z pokoju dziennego z rozkładaną sofą, aneksem kuchennym, sypialni z dwoma łóżkami, łazienki oraz wygodnego balkonu. Obejrzyj zdjęcia i sprawdź szczegóły wyposażenia."
                                        link="/apartament-4-osobowy"
                                        image={data.roomCovers.edges[3].node.childImageSharp.fluid}
                                    />

                                    <HorizontalCardBlock
                                        title="Apartament 5-osobowy (dwupokojowy)"
                                        description="Wygodny, dwupokojowy apartament dla pięciu osób składający się z pokoju dziennego z rozkładaną, dwuosobową sofą, aneksu kuchennego, sypialni z dwoma łóżkami (dużym double i pojedynczym twin), łazienki i balkonu. Zobacz zdjęcia pokoju i szczegóły wyposażenia."
                                        link="/apartament-5-osobowy"
                                        image={data.roomCovers.edges[4].node.childImageSharp.fluid}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <div className="section front__info pb-0">
                <div className="projects-4 pt-4 pb-4" data-background-color="gray">
                    <Container fluid>
                        <Row>
                            <Col className="px-0 order-md-1" md="6">
                                <Card className="card-fashion card-background">
                                    <Img
                                        fluid={data.bg3Front.childImageSharp.fluid}
                                        style={{
                                            position: "absolute",
                                            left: 0,
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                    <CardBody>
                                        <CardFooter className="text-left">
                                            <div className="stats">
                        <span>
                          <i className="now-ui-icons objects_globe"/>
                          Bogata oferta gastronomiczna
                        </span>
                                            </div>
                                        </CardFooter>
                                        <CardTitle className="text-left" tag="div">
                                            <h2>
                                                Tylko kilka minut spaceru od restauracji i sklepów
                                            </h2>
                                        </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0 order-md-2" md="6">
                                <div className="card-container">
                                    <Card className="card-fashion">
                                        <CardTitle tag="div">
                                            <h4>
                                                Dla dzieci bezpieczny i różnorodny plac zabaw oraz sala
                                                zabaw z konsolą do gier i kreatywnymi zabawkami.
                                            </h4>
                                        </CardTitle>
                                        <CardBody>
                                            <CardFooter>
                                                <div className="stats">
                          <span>
                            <i className="now-ui-icons education_paper"/>
                            Mnóstwo atrakcji dla najmłodszych
                          </span>
                                                </div>
                                            </CardFooter>
                                        </CardBody>
                                    </Card>
                                    <Card className="card-fashion card-background d-none d-md-block">
                                        <Img
                                            fluid={data.bg1Front.childImageSharp.fluid}
                                            style={{
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="px-0 order-md-3 order-4" md="6">
                                <div className="card-container">
                                    <Card className="card-fashion card-background d-none d-md-block">
                                        <Img
                                            fluid={data.bg4Front.childImageSharp.fluid}
                                            style={{
                                                position: "absolute",
                                                left: 0,
                                                top: 0,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                    </Card>
                                    <Card className="card-fashion arrow-left">
                                        <CardTitle tag="div">
                                            <h4>
                                                Na terenie obiektu miejsca do grillowania, wypoczynku i
                                                wspólnego spędzania czasu.
                                            </h4>
                                        </CardTitle>
                                        <CardBody>
                                            <CardFooter>
                                                <div className="stats">
                          <span>
                            <i className="now-ui-icons design_app"/>
                            Na wyłączność dla naszych gości
                          </span>
                                                </div>
                                            </CardFooter>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                            <Col className="px-0 order-md-4 order-3" md="6">
                                <Card className="card-fashion card-background">
                                    <Img
                                        fluid={data.bg2Front.childImageSharp.fluid}
                                        style={{
                                            position: "absolute",
                                            left: 0,
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Col md="10" className="mr-auto ml-auto">
                <div className="section" id="photos">
                    <Photos
                        title="Zobacz zdjęcia z Morskiej 5"
                        description="Zadbaliśmy nie tylko o wyposażenie pokoi, ale także przyjazne i praktyczne otoczenie. Zobacz, co Morska 5 oferuje swoim gościom."
                        photos={data.gallery}
                    />
                </div>
            </Col>

            <Col md="7" className="ml-auto mr-auto">
                <div className="section" id="contact">
                    <ContactInfo
                        title={`Zarezerwuj pobyt`}
                        description={`Osoby zainteresowane dokonaniem rezerwacji prosimy o kontakt telefoniczny
            lub mailowy. Warunkiem potwierdzenia rezerwacji jest wpłata zaliczki w
            wysokości 30% wartości całego pobytu.`}
                        telTitle={`Kliknij i zadzwoń do nas`}
                        telDescription={`Czekamy na Państwa telefony 7 dni w tygodniu, od 8:00 do 22:00`}
                        mailDescription={`Prosimy w treści maila wpisać termin, ilość i rodzaj pokojów oraz numer kontaktowy.`}
                        mailTitle={`Kliknij i napisz do nas`}
                    />
                </div>
            </Col>

            <div className="section contactWithMap pb-0">
                <Map
                    description={`Bez problemu znajdziesz nas w Google Maps, w przypadku kłopotów z dojazdem prosimy o kontakt.`}
                    ctaText={`Pokaż dojazd`}
                    lang="pl"
                />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query images {
        story1: file(relativePath: { eq: "story-1.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        story2: file(relativePath: { eq: "story-2.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        story3: file(relativePath: { eq: "story-3.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        story4: file(relativePath: { eq: "story-4.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        story5: file(relativePath: { eq: "story-5.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        temporaryRoom: file(relativePath: { eq: "temporary-room.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bg3Front: file(relativePath: { eq: "bg-3-front.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bg1Front: file(relativePath: { eq: "bg-1-front.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bg4Front: file(relativePath: { eq: "bg-4-front.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bg2Front: file(relativePath: { eq: "bg-2-front.jpg" }) {
            id
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery: allFile(
            filter: {
                relativeDirectory: { in: ["gallery/1", "gallery/2", "gallery/3"] }
            }
            sort: { fields: relativeDirectory }
        ) {
            edges {
                node {
                    id
                    childImageSharp {
                        sizes(maxWidth: 1800) {
                            ...GatsbyImageSharpSizes_withWebp
                        }
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        roomCovers: allFile(
            sort: { fields: name }
            filter: { relativeDirectory: { eq: "rooms/covers" } }
        ) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
